@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/Delivery_W_Lt.woff") format("woff"),
    url("../fonts/Delivery_W_Lt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 200;
  src: url("../fonts/Delivery_W_LtIt.woff") format("woff"),
    url("../fonts/Delivery_W_LtIt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 200;
  font-stretch: condensed;
  src: url("../fonts/Delivery_W_CdLt.woff") format("woff"),
    url("../fonts/Delivery_W_CdLt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Delivery_W_Lt.woff") format("woff"),
    url("../fonts/Delivery_W_Lt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Delivery_W_LtIt.woff") format("woff"),
    url("../fonts/Delivery_W_LtIt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 300;
  font-stretch: condensed;
  src: url("../fonts/Delivery_W_CdLt.woff") format("woff"),
    url("../fonts/Delivery_W_CdLt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Delivery_W_Rg.woff") format("woff"),
    url("../fonts/Delivery_W_Rg.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Delivery_W_It.woff") format("woff"),
    url("../fonts/Delivery_W_It.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/Delivery_W_Bd.woff") format("woff"),
    url("../fonts/Delivery_W_Bd.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/Delivery_W_BdIt.woff") format("woff"),
    url("../fonts/Delivery_W_BdIt.woff2") format("woff2");
}

@font-face {
  font-family: "Delivery";
  font-style: normal;
  font-weight: 800;
  font-stretch: condensed;
  src: url("../fonts/Delivery_W_CdBlk.woff") format("woff"),
    url("../fonts/Delivery_W_CdBlk.woff2") format("woff2");
}

* {
  font-family: Delivery, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

html {
  /* override qlik styles below */
  width: unset;
  position: unset;
  touch-action: unset;
}
body {
  /* override qlik styles below */
  width: unset;
  position: unset;
  touch-action: unset;
  font-size: 16px;
  color: black;
  font-family: unset !important;
}

a,
a:visited,
a:hover,
a:focus {
  color: black;
  text-decoration: underline;
}

body.disable-scroll {
  overflow: hidden;
}

html.bg-gray05 {
  background: #f2f2f2;
}

body[class^="bg-"], body[class*=" bg-"] {
  background: unset;
}

html.h100p {
  height: 100%;
  overflow: hidden;
}
body.h100p {
  height: 100%;
  overflow: hidden;
}
body.h100p #root {
  height: 100%;
  overflow: hidden;
}
body.h100p .outerWrapper {
  height: 100%;
  overflow: hidden;
}

.inline-block {
  display: inline-block;
}

.w100p {
  min-width: 100%;
}

body.w100p .outerWrapper {
  min-width: 100%;
  width: unset;
}

.grecaptcha-badge {
  transform: translateY(-55px);
  visibility: hidden;
}

body.show-captcha-badge .grecaptcha-badge {
  visibility: visible;
}

.disableBlock {
  opacity: 0.5;
  pointer-events: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ie-label-80p label {
    max-width: 80%;
  }
  .ie-label-70p label {
    max-width: 70%;
  }
  .ie-max-300 {
    max-width: 300px;
  }
}

.mapContainer svg.H_icon {
  fill: #d40511;
}

.mapContainer svg.H_icon:hover,
.mapContainer .H_active svg.H_icon,
.mapContainer .H_active svg.H_icon:hover {
  fill: #eb131e !important;
}

.mapContainer .H_disabled svg.H_icon {
  fill: rgba(0, 0, 0, 0.2) !important;
}

html.disable-browser-back {
  overscroll-behavior-x: none;
}

body.disable-browser-back {
  overscroll-behavior-x: none;
}

#dhl-va-widget-iframe {
  bottom: 0.5rem !important;
  right: 0.5rem !important;
}
